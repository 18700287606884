<template>
  <div class="outer-container" v-if="loyalty">
    <div class="loyalty-container">
      <p class="loyalty-text">{{ loyalty.loyaltyText }}</p>
    </div>
    <button class="primary-button loyalty-cta" @click="openLink">
      {{ loyalty.loyaltyCtaText }}
    </button>
  </div>
</template>

<script setup>
import store from "@/store";

const loyalty = store.state.loyalty.loyalty;

function openLink() {
  gtag("event", "click_loyalty_cta");
  window.open(loyalty.loyaltyCtaLink, "_blank").focus();
}

</script>

<style scoped>
@import "../styles/components/loyalty-container.css";
</style>
