<script setup>
import { ref, onMounted } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import LoyaltyContainer from "@/components/LoyaltyContainer.vue";
import ReceiptCard from "@/components/ReceiptCard.vue";
import Spinner from "@/components/Spinner.vue";

const loading = ref(true);
const receipt = ref(null);
const loyalty = ref(null);
const router = useRouter();

const loadError = () => {
  store.dispatch(
    "general/setError",
    "No receipt found, or error fetching receipt"
  );
  router.push({ name: "Error" });
};

const getReceiptData = async (transaction) => {
  fetch(`${import.meta.env.VITE_API_URL}/app/receipt/details`, {
    method: "POST",
    body: JSON.stringify({ receiptHash: transaction }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data && data.receiptDetail) {
        receipt.value = data.receiptDetail;
        await store.dispatch("receipt/setReceipt", data);
      }
      if (data && data.loyaltyDetail) {
        loyalty.value = data.loyaltyDetail;
        await store.dispatch("loyalty/setLoyalty", data.loyaltyDetail);
      }
      if (!data || (!data.receiptDetail && !data.loyaltyDetail)) {
        loadError();
      }
      loading.value = false;
    });
};

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const transaction = urlParams.get("transaction");
  const id = urlParams.get("id");

  if (transaction || id) getReceiptData(transaction ? transaction : id);
  else loadError();
});
</script>

<template>
  <div class="page-container">
    <div class="body" v-if="!loading">
      <LoyaltyContainer v-if="loyalty"></LoyaltyContainer>
      <ReceiptCard v-if="receipt"></ReceiptCard>
    </div>
    <div class="spinner" v-else>
      <Spinner />
    </div>
  </div>
</template>

<style scoped>
@import "../styles/pages/receipt-page.css";
</style>
